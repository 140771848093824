<template>
  <v-card outlined>
    <v-toolbar color="indigo-darken-4" dark>
      <v-btn icon @click="dialog = true" class="white--text">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <h5 class="white--text">BITÁCORA DE EVENTOS</h5>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <v-timeline v-if="orderedBitacoras" side="end" align="start">
        <v-timeline-item
          v-for="(bitacora, index) in orderedBitacoras"
          :key="index"
          dot-color="green"
          size="small"
        >
          <div class="d-flex">
            <strong class="me-4">{{ moment(bitacora.fecha).format('DD-MM-YYYY HH:mm') }}</strong>
            <div>
              <strong>{{ bitacora.titulo }}</strong>
              <div class="text-caption">
                {{ bitacora.descripcion }}
              </div>
            </div>
          </div>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>

    <!-- Diálogo para Añadir Bitácora -->
    <v-dialog v-model="dialog" persistent max-width="500">
      <v-card>
        <v-card-title>
          <span class="text-h6">Añadir Bitácora</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-text-field
              v-model="newBitacora.titulo"
              label="Título"
              required
            ></v-text-field>
            <v-textarea
              v-model="newBitacora.descripcion"
              label="Descripción"
              required
            ></v-textarea>
            <v-text-field
              v-model="newBitacora.fecha"
              label="Fecha y hora"
              type="datetime-local"
              required
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="addBitacora">Guardar</v-btn>
          <v-btn color="red darken-1" text @click="dialog = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  name: "BitacorasTimeline",
  data() {
    return {
      moment,
      dialog: false,
      valid: false,
      newBitacora: {
        titulo: "",
        descripcion: "",
        fecha: "",
      },
      bitacoras: [
        {
          fecha: "2024-03-10T14:00:00",
          titulo: "Inicio de consulta",
          descripcion: "El paciente fue ingresado en el sistema.",
        },
        {
          fecha: "2024-03-11T10:30:00",
          titulo: "Evaluación médica inicial",
          descripcion: "El médico completó la evaluación física del paciente.",
        },
        {
          fecha: "2024-03-12T15:45:00",
          titulo: "Revisión de exámenes",
          descripcion: "Se revisaron los resultados de laboratorio y radiología.",
        },
        {
          fecha: "2024-03-13T09:20:00",
          titulo: "Alta médica",
          descripcion: "El paciente fue dado de alta con instrucciones médicas específicas.",
        },
      ],
    };
  },
  methods: {
    addBitacora() {
      if (this.$refs.form.validate()) {
        this.bitacoras.push({ ...this.newBitacora });
        this.dialog = false;

        // Limpiar el formulario
        this.newBitacora = {
          titulo: "",
          descripcion: "",
          fecha: "",
        };
      }
    },
  },
  computed: {
    orderedBitacoras() {
      // Devuelve una copia ordenada sin modificar el arreglo original
      return [...this.bitacoras].sort((a, b) => new Date(b.fecha) - new Date(a.fecha));
    },
  },
};
</script>
