<template>
    <v-card outlined>
      <v-toolbar color="indigo-darken-4" dark>
        <v-spacer></v-spacer>
        <h5 class="white--text">HISTORIAL DE DESCANSOS MÉDICOS</h5>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-timeline v-if="descansos_medicos" side="end" align="start">
          <v-timeline-item
            v-for="(descanso, index) in descansos_medicos"
            :key="index"
            dot-color="blue"
            size="small"
            @click="selectDescanso(descanso)"
            class="cursor-pointer"
          >
            <div class="d-flex">
              <strong class="me-4">
                {{ moment(descanso.fechaInicio).format('DD-MM-YYYY') }} -
                {{ moment(descanso.fechaFin).format('DD-MM-YYYY') }}
              </strong>
              <div>
                <strong>{{ descanso.motivo }}</strong>
                <div class="text-caption">
                  {{ descanso.detalle }}
                </div>
              </div>
            </div>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  import moment from "moment";
  
  export default {
    name: "DescansosMedicosTimeline",
    data() {
      return {
        moment,
      };
    },
    props: {
      descansos_medicos: {
        type: Array,
        required: true,
      },
    },
    methods: {
      selectDescanso(descanso) {
        // Emitir el descanso seleccionado al componente padre
        this.$emit("descanso-seleccionado", descanso);
      },
    },
  };
  </script>
  
  <style scoped>
  .cursor-pointer {
    cursor: pointer;
  }
  </style>
  