<template>
  <v-card outlined>
    <v-sheet rounded="lg">
      <v-toolbar dense color="indigo-darken-4" dark>
        <v-spacer></v-spacer>
        <h5>DESICION MEDICA</h5>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-select :items="estaciones" item-title="descripcion" item-value="id" prepend-icon="mdi-map-marker"
          v-model="punto_atencion" density="compact" hide-details label="PUNTO" >
          <template v-slot:item="{ props, item }">
            <v-list-item v-bind="props" :subtitle="item.raw.sede.descripcion"></v-list-item>
          </template>
        </v-select>
        <v-select v-model="estado" v-if="estadosDeclaracionMedicamentos" :items="estadosDeclaracionMedicamentos"
          prepend-icon="mdi-check-circle-outline" item-value="id" item-title="descripcion" label="ESTADO" hide-details
          density="compact"></v-select>
        <v-select v-if="estado == 2" :items="aptitudesDeclaracionMedicamentos" v-model="aptitud" item-value="id"
          prepend-icon="mdi-account-check-outline" hide-details item-title="descripcion" density="compact" label="APTITUD"
          offset-y></v-select>
        <v-textarea v-model="observaciones" label="OBSERVACIONES" density="compact" prepend-icon="mdi-comment"
          hide-details></v-textarea>
      </v-card-text>
    </v-sheet>
  </v-card>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: 'DecisionMedica',
  props: ['data'],
  data() {
    return {
      menu: false,
      seguimiento: this.data,
      decision: null,
      errors: null
    }
  },
  computed: {
    ...mapState(['estadosDeclaracionMedicamentos', 'aptitudesDeclaracionMedicamentos']),
    estaciones() {
      return this.$store.state.estaciones;
    },
    observaciones: {
      get() { return this.$store.state.declaracion_medicamento_seleccionada.observaciones; },
      set(val) { this.$store.commit('SET_DECLARACION_MEDICAMENTO_SELECCIONADA_OBSERVACIONES', val) }
    },
    estado: {
      get() { return this.$store.state.declaracion_medicamento_seleccionada.estado_declaracionmedicamento_id; },
      set(val) { this.$store.commit('SET_DECLARACION_MEDICAMENTO_SELECCIONADA_ESTADO', val) }
    },
    aptitud: {
      get() { return this.$store.state.declaracion_medicamento_seleccionada.aptitud_declaracionmedicamento_id; },
      set(val) { this.$store.commit('SET_DECLARACION_MEDICAMENTO_SELECCIONADA_APTITUD', val) }
    },
    punto_atencion: {
      get() { return this.$store.state.declaracion_medicamento_seleccionada.estacion_id; },
      set(val) { this.$store.commit('SET_DECLARACION_MEDICAMENTO_SELECCIONADA_PUNTO_ATENCION', val) }
    },
  },
  methods: {

  },
  watch: {
  },
  created() {
    this.$store.dispatch('fetchEstaciones')
    this.$store.dispatch('fetchEstadosDeclaracionMedicamentos')
    this.$store.dispatch('fetchAptitudesDeclaracionMedicamentos')
  }
}

</script>