<template>
  <v-card>
    <v-toolbar color="indigo-darken-4">
      <v-spacer></v-spacer>
      <h5>ADJUNTOS</h5>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <v-table>
        <thead>
          <tr>
            <th class="text-left">Nombre</th>
            <th class="text-center">Descargar</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in adjuntos" :key="item.id">
            <td class="text-left">{{ item.ruta }}</td>
            <td class="text-center">
              <v-btn @click="descargarArchivo(item.id, item.ruta)" density="compact" icon="mdi-download"></v-btn>
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "AdjuntosVisitante",
  computed: {
    // Obtén los adjuntos desde el módulo 'formatoVisitaFamiliares'
    adjuntos() {
      return this.$store.state.formatoVisitaFamiliares.detalleVisitante?.adjuntos || [];
    },
  },
  methods: {
    descargarArchivo(id, nombreArchivo) {
      // URL base para la API
      const baseURL = process.env.VUE_APP_API_URL || "http://localhost";

      // Construir URL de descarga
      const url = `${baseURL}/api/adjuntosfvfamiliar/${id}`;

      // Crear elemento <a> para forzar la descarga
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nombreArchivo);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>
