<template>
  <v-card>
    <v-toolbar color="indigo-darken-4">
      <v-spacer></v-spacer>
      <h5>HABILITACIÓN</h5>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col cols="6" align-self="end">
          <v-img class="mx-auto bg-grey-lighten-2" height="125" width="100" contain :src="foto"></v-img>
        </v-col>
        <v-col cols="6" class="text-center">
          <div class="text-overline">{{ user.cmp }}</div>
          <div class="text-overline">{{ user.nombres }}</div>
          <div class="text-overline">{{ user.apellido_paterno }} {{ user.apellido_materno }}</div>
          <div class="text-overline text-green">HABILITADO</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card class="mt-0" :disabled="programacionActiva">
            <v-card-title>Habilitación Visita</v-card-title>
            <v-card-text>
              <v-select
                hide-details
                v-model="habilitacion.fvfamiliar_habilitacion_id"
                label="HABILITACION"
                item-title="descripcion"
                item-value="id"
                density="compact"
                :items="habilitaciones"
              ></v-select>
              <v-textarea
                v-model="habilitacion.observaciones"
                label="OBSERVACIONES"
                rows="4"
                density="compact"
              ></v-textarea>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "HabilitacionVisitante",
  computed: {
    user() {
      return this.$store.state.user || {};
    },
    habilitacion() {
      const detalle = this.$store.state.formatoVisitaFamiliares.detalleVisitante;
      return detalle && detalle.habilitacion
        ? detalle.habilitacion
        : { fvfamiliar_habilitacion_id: null, observaciones: "", user_id: this.user.id };
    },
    habilitaciones() {
      return this.$store.state.formatoVisitaFamiliares.habilitacionesCalificacionFormatoVisitante || [];
    },
    programacionActiva() {
      const detalle = this.$store.state.formatoVisitaFamiliares.detalleVisitante;
      return detalle && detalle.programacion && detalle.programacion.length > 0;
    },
    foto() {
      return `https://200.48.13.39/conoce_a_tu_medico/fotos/${this.user.cmp}.jpg`;
    },
  },
  watch: {
    "habilitacion.fvfamiliar_habilitacion_id": {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.actualizarDatos({ fvfamiliar_habilitacion_id: newValue });
        }
      },
      deep: true,
    },
    "habilitacion.observaciones": {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.actualizarDatos({ observaciones: newValue });
        }
      },
      deep: true,
    },
  },
  methods: {
    actualizarDatos(cambios) {
    const detalle = this.$store.state.formatoVisitaFamiliares.detalleVisitante || {};

    // Garantizar que detalle.habilitacion exista y agregar user_id
    const nuevaHabilitacion = {
      ...(detalle.habilitacion || {}),
      user_id: this.user.id, // Asegurar que user_id esté actualizado
      ...cambios,
    };


    // Actualizar estado
    this.$store.commit("formatoVisitaFamiliares/SET_DETALLE_VISITANTE", {
      ...detalle,
      habilitacion: nuevaHabilitacion
    });
  },
    cargarHabilitaciones() {
      this.$store.dispatch("formatoVisitaFamiliares/fetchFvHabilitacionFormatoVisitante");
    },
  },
  created() {
    this.cargarHabilitaciones();
  },
};
</script>
