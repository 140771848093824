<template>
  <v-row dense>
    <v-col cols="12" lg="3" md="12" sm="12" xs="12">
      <InformacionVisitanteFamiliar class="mt-2 mx-4" :visitante="detalleVisitante?.visitante" />
      <FuncionesVitalesVisitanteFamiliar class="mt-2 mx-4" :funciones="detalleVisitante?.signosvitales" />
    </v-col>
    <v-col cols="12" lg="4" md="12" sm="12" xs="12">
      <CuestionarioVisitanteFamiliar class="mt-2 mx-4" :cuestionario="detalleVisitante?.preguntas" />
      <MedicacionActualFamiliar class="mt-2 mx-4" />
    </v-col>
    <v-col cols="12" lg="5" md="12" sm="12" xs="12">
      <AdjuntosVisitanteFamiliar class="mt-2 mx-4" />
      <CalificacionVisitanteFamiliar class="mt-2 mx-4" />
      <div class="d-flex ma-4">
        <v-spacer></v-spacer>
        <v-btn append-icon="mdi-printer" @click="abrirDialogImprimir()" color="blue" class="mx-2">IMPRIMIR</v-btn>
        <v-btn :loading="loading" append-icon="mdi-content-save" color="success" @click="guardar()">
          GUARDAR
          <template v-slot:loader>
            <v-progress-linear indeterminate></v-progress-linear>
          </template>
        </v-btn>
      </div>
      <DialogFormatoVisita ref="dialogPdf" />
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";

import DialogFormatoVisita from "@/components/DialogFormatoVisita";
import moment from "moment";
import InformacionVisitanteFamiliar from "@/components/FormatoVisitaFamiliares/InformacionVisitanteFamiliar.vue";
import FuncionesVitalesVisitanteFamiliar from "@/components/FormatoVisitaFamiliares/FuncionesVitalesVisitanteFamiliar.vue";
import MedicacionActualFamiliar from "@/components/FormatoVisitaFamiliares/MedicacionActualFamiliar.vue";
import CuestionarioVisitanteFamiliar from "@/components/FormatoVisitaFamiliares/CuestionarioVisitanteFamiliar.vue";
import AdjuntosVisitanteFamiliar from "@/components/FormatoVisitaFamiliares/AdjuntosVisitanteFamiliar.vue";
import CalificacionVisitanteFamiliar from "@/components/FormatoVisitaFamiliares/CalificacionVisitanteFamiliar.vue";

export default {
  name: "DetallePaciente",
  components: {
    DialogFormatoVisita,
    InformacionVisitanteFamiliar,
    FuncionesVitalesVisitanteFamiliar,
    MedicacionActualFamiliar,
    CuestionarioVisitanteFamiliar,
    AdjuntosVisitanteFamiliar,
    CalificacionVisitanteFamiliar
  },
  data() {
    return {
      moment,
      loading: false,
    };
  },
  computed: {
    ...mapState("formatoVisitaFamiliares", ["detalleVisitante"]),
  },
  methods: {
    ...mapActions("formatoVisitaFamiliares", [
      "fetchFormatoVisitaID",
      "guardarFormatoVisita",
    ]),
    async cargarDatos(idVisitante) {
      try {
        await this.fetchFormatoVisitaID(idVisitante);
      } catch (e) {
        this.$store.commit("SHOW_ERROR_SNACKBAR", e.message);
      }
    },
    async guardar() {
      this.loading = true;
      try {
        await this.guardarFormatoVisita();
      } finally {
        this.loading = false;
      }
    },
    abrirDialogImprimir() {
      this.$store.commit("SET_DIALOG_IMPRIMIR_FORMATO_VISITA", true);
      this.$refs.dialogPdf.onShowPdf(this.$route.params.id);
    },
  },
  created() {
    this.cargarDatos(this.$route.params.id);
  },
};
</script>
