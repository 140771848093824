<template>
  <v-card>
    <v-toolbar color="indigo-darken-4">
      <v-spacer></v-spacer>
      <h5>INFORMACIÓN DEL PACIENTE</h5>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text v-if="paciente">
      <v-img class="align-end" contain height="200" :src="ruta + paciente.numero_documento">
      </v-img>
      <v-text-field v-model="paciente.nombres" density="compact" hide-details readonly label="NOMBRES"></v-text-field>
      <v-text-field v-model="apellidos" density="compact" hide-details readonly label="APELLIDOS"></v-text-field>
      <div class="d-flex">
        <v-text-field v-model="paciente.numero_documento" hide-details density="compact" class="mr-2" readonly
          label="DNI"></v-text-field>
        <v-text-field v-model="paciente.nro_registro" hide-details density="compact" readonly
          label="REGISTRO"></v-text-field>
      </div>
      <v-text-field v-model="paciente.celular" density="compact" hide-details label="CELULAR" readonly></v-text-field>
      <v-text-field v-model="paciente.correo" density="compact" hide-details label="CORREO" readonly
        append-inner-icon="mdi-email" @click:append-inner="enviarCorreo(paciente.correo)"></v-text-field>
      <!--       <v-text-field v-model="paciente.puesto" density="compact" hide-details label="PUESTO" readonly></v-text-field> -->
      <v-text-field v-model="paciente.posicion.descripcion" density="compact" hide-details label="POSICION"
        readonly></v-text-field>
      <v-text-field v-model="paciente.posicion.superintendencia.descripcion" density="compact" hide-details
        label="SUPERINTENDENCIA" readonly></v-text-field>
      <v-text-field v-model="paciente.posicion.superintendencia.gerencia.descripcion" density="compact" hide-details
        label="GERENCIA" readonly></v-text-field>
      <v-text-field v-model="paciente.posicion.superintendencia.gerencia.gerencia_general.descripcion" density="compact"
        hide-details label="GERENCIA GENERAL" readonly></v-text-field>
      <div class="d-flex">
        <v-text-field v-model="paciente.edad" density="compact" hide-details readonly dense label="EDAD"></v-text-field>
        <v-text-field v-model="paciente.sexo" density="compact" hide-details readonly dense label="SEXO"></v-text-field>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'DatosTrabajador',
  data() {
    return {
      ruta: process.env.VUE_APP_API_URL + '/api/mostrarFotoMediweb/'
    }
  },
  methods: {
    enviarCorreo(correo) {
      window.location.href = `mailto:${correo}?subject=DECLARACION DE MEDICAMENTOS&body=Esta es una prueba de envio"`;
    }
  },
  computed: {
    apellidos() {
      if (this.$store.state.descansosMedicos.descansoSeleccionado && this.$store.state.descansosMedicos.descansoSeleccionado.paciente) return this.$store.state.descansosMedicos.descansoSeleccionado.paciente.apellido_paterno + ' ' + this.$store.state.descansosMedicos.descansoSeleccionado.paciente.apellido_materno
      return null
    },
    paciente: {
      get() {
        if (this.$store.state.descansosMedicos.descansoSeleccionado && this.$store.state.descansosMedicos.descansoSeleccionado.paciente) return this.$store.state.descansosMedicos.descansoSeleccionado.paciente
        return null
      },
      set() {

      }
    }
  }
}
</script>