<template>
  <v-card>
    <v-toolbar color="indigo-darken-4">
      <v-spacer></v-spacer>
      <h5>FUNCIONES VITALES</h5>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <v-text-field
        v-model="signos.peso"
        density="compact"
        hide-details
        type="number"
        label="PESO"
        suffix="Kg"
      ></v-text-field>
      <v-text-field
        v-model="signos.talla"
        density="compact"
        hide-details
        type="number"
        label="TALLA"
        suffix="mts"
      ></v-text-field>
      <v-text-field v-model="imc.valor" readonly density="compact" hide-details type="number" label="IMC">
        <template v-slot:append-inner>
          <v-icon :icon="imc.icon" :color="imc.color"></v-icon>
        </template>
      </v-text-field>
      <v-text-field
        v-model="signos.presion_arterial"
        density="compact"
        hide-details
        label="PRESION ARTERIAL"
      ></v-text-field>
      <v-text-field
        v-model="signos.frec_cardiaca"
        type="number"
        density="compact"
        hide-details
        label="FRECUENCIA CARDIACA"
        suffix="x’"
      ></v-text-field>
      <v-text-field
        v-model="signos.frec_respiratoria"
        type="number"
        density="compact"
        hide-details
        label="FRECUENCIA RESPIRATORIA"
        suffix="x’"
      ></v-text-field>
      <v-text-field
        v-model="signos.saturacion_o2"
        type="number"
        density="compact"
        hide-details
        label="SATURACION O2"
        suffix="%"
      ></v-text-field>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "FuncionesVitales",
  data() {
    return {
      imc: {
        valor: "",
        color: "",
        icon: "",
      },
    };
  },
  computed: {
    signos() {
      const signosVitales = this.$store.state.formatoVisitaFamiliares.detalleVisitante?.signosvitales;
      return signosVitales
        ? signosVitales
        : { peso: "", talla: "", presion_arterial: "", frec_cardiaca: "", frec_respiratoria: "", saturacion_o2: "" };
    },
  },
  watch: {
    "signos.peso": {
      handler(newValue) {
        this.updateSignos({ peso: newValue });
      },
      immediate: true,
    },
    "signos.talla": {
      handler(newValue) {
        this.updateSignos({ talla: newValue });
      },
      immediate: true,
    },
    "signos.presion_arterial": {
      handler(newValue) {
        this.updateSignos({ presion_arterial: newValue });
      },
    },
    "signos.frec_cardiaca": {
      handler(newValue) {
        this.updateSignos({ frec_cardiaca: newValue });
      },
    },
    "signos.frec_respiratoria": {
      handler(newValue) {
        this.updateSignos({ frec_respiratoria: newValue });
      },
    },
    "signos.saturacion_o2": {
      handler(newValue) {
        this.updateSignos({ saturacion_o2: newValue });
      },
    },
  },
  methods: {
    updateSignos(updatedFields) {
      const currentSignos = { ...this.signos, ...updatedFields };
      this.$store.commit("formatoVisitaFamiliares/SET_SIGNOS_VITALES", currentSignos);
      if (updatedFields.peso || updatedFields.talla) {
        this.calculateIMC();
      }
    },
    calculateIMC() {
      const peso = parseFloat(this.signos.peso);
      const talla = parseFloat(this.signos.talla);
      if (!isNaN(peso) && !isNaN(talla) && talla !== 0) {
        const imc = peso / (talla * talla);
        this.imc.valor = imc.toFixed(2);
        this.imc.color = this.getIMCColor(imc);
        this.imc.icon = this.getIMCIcon(imc);
      } else {
        this.imc.valor = "";
        this.imc.color = "";
        this.imc.icon = "";
      }
    },
    getIMCColor(imc) {
      if (imc < 18.5) return "blue";
      if (imc >= 18.5 && imc < 24.9) return "green";
      if (imc >= 25 && imc < 30) return "orange";
      return "red";
    },
    getIMCIcon(imc) {
      if (imc < 18.5) return "mdi-alert";
      if (imc >= 18.5 && imc < 24.9) return "mdi-check-circle-outline";
      if (imc >= 25 && imc < 30) return "mdi-alert";
      return "mdi-close-circle-outline";
    },
  },
  mounted() {
    this.calculateIMC(); // Calculate initial IMC on mount
  },
};
</script>
