<template>
  <div>
    <v-card outlined class="mt-0">
      <v-card-title class="d-flex justify-space-between">
        LISTADO DE DESCANSOS MÉDICOS
        <v-btn prepend-icon="mdi-plus" class="ms-2" color="orange-darken-1" @click="abrirDialogoAgregar">
          Nuevo Descanso Médico
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table-server v-if="descansos" :headers="headers" :items="descansos" :loading="isLoading"
          v-model:items-per-page="itemsPerPage" v-model:options="options" @update:options="cargarData()"
          :items-length="totalItems" density="compact" class="elevation-0" :items-per-page-options="itemsPorPagina">
          <template v-slot:[`item.created_at`]="{ item }">
            {{ moment(item.created_at).format('DD/MM/YYYY') }}
          </template>
          <template v-slot:[`item.inicio`]="{ item }">
            {{ moment(item.inicio).format('DD/MM/YYYY') }}
          </template>
          <template v-slot:[`item.fin`]="{ item }">
            {{ moment(item.fin).format('DD/MM/YYYY') }}
          </template>
          <template v-slot:[`item.diagnostico`]="{ item }">
            {{ item.diagnostico.toUpperCase() }}
          </template>
          <template v-slot:[`item.estado`]="{ item }">
            <v-chip :color="item.estado.color" size="small">{{ item.estado.descripcion }}</v-chip>
          </template>
          <template v-slot:[`item.ver`]="{ item }">
            <div class="d-flex">
              <v-btn @click="verDetalle(item)" density="compact" variant="text" icon="mdi-magnify"></v-btn>
              <v-btn
                v-if="$store.getters['currentUser'].rol.permiso.some(permiso => permiso.descripcion === 'delete_descanso')"
                @click="showConfirmDialog(item)" density="compact" variant="text" icon="mdi-delete"></v-btn>
            </div>
          </template>
          <template v-slot:thead>
            <tr>
              <td>
                <v-row no-gutters>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="params.queryFechaInicio" hide-details label="Fecha Inicio" type="date"
                      class="ma-2" density="compact"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="params.queryFechaFin" hide-details label="Fecha Fin" type="date" class="ma-2"
                      density="compact"></v-text-field>
                  </v-col>
                </v-row>
              </td>
              <td>
                <v-text-field v-model="params.queryMotivo" hide-details placeholder="Buscar Motivo" type="text"
                  class="ma-2" density="compact"></v-text-field>
              </td>
              <td>
                <v-select v-model="params.queryEstado" item-value="value" item-title="text" label="Buscar Estado"
                  multiple class="ma-2" hide-details :items="estados" density="compact"></v-select>
              </td>
            </tr>
          </template>
        </v-data-table-server>
      </v-card-text>
    </v-card>

    <!-- Diálogo para agregar descanso médico -->
    <v-dialog v-model="dialogAgregar" max-width="600px">
      <v-card>
        <v-card-title>
          Seleccionar Paciente
        </v-card-title>
        <v-card-text>
          <v-autocomplete v-model="pacienteSeleccionado" :items="pacientes" v-model:search="search"
            item-title="full_name" item-value="id" label="Buscar Paciente" placeholder="Escriba para buscar..."
            :loading="loadingPacientes" hide-no-data clearable dense>
          </v-autocomplete>


        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" :disabled="!pacienteSeleccionado" @click="guardarDescanso">Seleccionar</v-btn>
          <v-btn text @click="dialogAgregar = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmDialog :isVisible="showDeleteDialog" title="Confirmación"
      message="¿Está seguro de borrar este descanso médico?" @update:isVisible="showDeleteDialog = $event"
      @confirm="confirmarEliminar" @cancel="cancelarEliminar" />
  </div>
</template>

<script>
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import moment from "moment";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: 'ListadoDescansosMedicosView',
  data() {
    return {
      itemsPorPagina: [
        { value: 10, title: '10' },
        { value: 25, title: '25' },
        { value: 50, title: '50' },
        { value: 100, title: '100' }
      ],
      itemsPerPage: 10,
      search: null,
      moment,
      options: {},
      params: {},
      dialogAgregar: false,
      pacienteSeleccionado: null,
      pacientes: [],
      loadingPacientes: false,
      showDeleteDialog: false,
      itemToDelete: null,
      estados: [
        { value: 'APROBADO', text: 'Aprobado' },
        { value: 'RECHAZADO', text: 'Rechazado' },
        { value: 'PENDIENTE', text: 'Pendiente' }
      ]
    };
  },
  components: {
    ConfirmDialog,
  },
  computed: {
    ...mapState("descansosMedicos", ["data", "total", "isLoading"]),
    ...mapGetters("descansosMedicos", ["descansos"]),
    totalItems() {
      return this.total;
    },
    headers() {
      return [
        { title: 'Fecha', align: 'center', sortable: false, key: 'created_at' },
        { title: 'Paciente', align: 'center', sortable: false, key: 'paciente.full_name' },
        { title: 'Numero de Documento', align: 'center', sortable: false, key: 'paciente.numero_documento' },
        { title: 'Fecha Inicio', align: 'center', sortable: false, key: 'inicio' },
        { title: 'Fecha Fin', align: 'center', sortable: false, key: 'fin' },
        { title: 'Diagnostico', align: 'center', sortable: false, key: 'diagnostico' },
        { title: 'Estado', align: 'center', sortable: false, key: 'estado' },
        //{ title: 'CIE10', align: 'center', sortable: false, key: 'estado' },
        { title: 'Resumen', align: 'center', sortable: false, key: 'resumen' },
        { title: 'Acciones', align: 'center', sortable: false, key: 'ver' },
      ]
    }
  },
  methods: {
    ...mapActions("descansosMedicos", ["fetchDescansos", "deleteDescanso", "saveDescanso"]),
    async cargarData() {
      const params = {
        ...this.params,
        ...this.options,
      };
      try {
        await this.fetchDescansos(params);
      } catch (e) {
        this.$store.commit("SHOW_ERROR_SNACKBAR", e.message);
      }
    },
    async cargarPacientes(searchValue) {
      // Validar que el texto de búsqueda tenga al menos 3 caracteres
      if (!searchValue || searchValue.length < 3) {
        this.pacientes = []; // Limpia los resultados anteriores
        return;
      }
      this.loadingPacientes = true; // Muestra el indicador de carga
      try {
        // Llama a la acción Vuex para buscar pacientes
        const data = await this.$store.dispatch("descansosMedicos/searchPacientes", { query: searchValue });
        // Asigna los datos directamente al estado pacientes
        this.pacientes = data;
      } catch (e) {
        console.error("Error al buscar pacientes:", e.message);
        //this.$store.commit("SHOW_ERROR_SNACKBAR", e.message);
      } finally {
        this.loadingPacientes = false; // Oculta el indicador de carga
      }
    },
    abrirDialogoAgregar() {
      this.dialogAgregar = true;
      this.pacienteSeleccionado = null;
    },
    async guardarDescanso() {
      if (this.pacienteSeleccionado) {
        try {
          await this.saveDescanso({ paciente_id: this.pacienteSeleccionado });
          this.dialogAgregar = false;
          this.cargarData();
          this.$store.commit("SHOW_SUCCESS_SNACKBAR", "Descanso médico agregado correctamente");
        } catch (e) {
          this.$store.commit("SHOW_ERROR_SNACKBAR", e.message);
        }
      }
    },
    verDetalle(item) {
      this.$router.push({ path: `/detalledescansomedico/${item.id}` });
    },
    showConfirmDialog(item) {
      this.itemToDelete = item;
      this.showDeleteDialog = true;
    },
    async confirmarEliminar() {
      if (this.itemToDelete) {
        try {
          await this.deleteDescanso(this.itemToDelete.id);
          this.showDeleteDialog = false;
          this.itemToDelete = null;
          this.cargarData();
        } catch (e) {
          this.$store.commit("SHOW_ERROR_SNACKBAR", e.message);
        }
      }
    },
    cancelarEliminar() {
      this.showDeleteDialog = false;
      this.itemToDelete = null;
    },
  },
  created() {
    this.cargarData();
  },
  watch: {
    search(newVal) {
      // Observa cambios en el texto del autocomplete
      this.cargarPacientes(newVal);
    },
    'params.queryFechaInicio': "cargarData",
    'params.queryFechaFin': "cargarData",
    'params.queryMotivo': "cargarData",
    'params.queryEstado': "cargarData",
  },
};
</script>
