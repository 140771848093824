<template>
  <v-card>
    <v-toolbar color="indigo-darken-4">
      <v-spacer></v-spacer>
      <h5>DATOS DEL VISITANTE</h5>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text v-if="detalleVisitante && detalleVisitante.visitante">
      <v-text-field v-model="nombreCompleto" density="compact" hide-details label="NOMBRE COMPLETO"
        readonly></v-text-field>
      <v-text-field v-model="detalleVisitante.visitante.numero_documento" hide-details density="compact"
        label="NÚMERO DE DOCUMENTO" readonly></v-text-field>
      <div class="d-flex">
        <v-text-field v-model="fechaNacimiento" hide-details density="compact" class="mr-2" label="FECHA DE NACIMIENTO"
          readonly></v-text-field>
        <v-text-field v-model="detalleVisitante.visitante.edad" hide-details density="compact" label="EDAD"
          readonly></v-text-field>
      </div>
      <v-text-field v-if="detalleVisitante.visitante.tipovisitante"
        v-model="detalleVisitante.visitante.tipovisitante.descripcion" hide-details density="compact"
        label="TIPO DE VISITANTE" readonly></v-text-field>
      <v-text-field v-if="detalleVisitante.visitante.tiposeguro"
        v-model="detalleVisitante.visitante.tiposeguro.descripcion" hide-details density="compact"
        label="TIPO DE SEGURO" readonly></v-text-field>
      <v-text-field v-if="detalleVisitante.visitante.centrosalud"
        v-model="detalleVisitante.visitante.centrosalud.descripcion" hide-details density="compact"
        label="CENTRO DE SALUD" readonly></v-text-field>
      <v-text-field v-model="detalleVisitante.visitante.contacto_emergencia" hide-details density="compact"
        label="CONTACTO DE EMERGENCIA" readonly></v-text-field>
      <v-text-field v-model="detalleVisitante.visitante.telefono_contacto" hide-details density="compact"
        label="NÚMERO DE CONTACTO" readonly></v-text-field>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "DatosVisitante",
  computed: {
    ...mapState("formatoVisitaFamiliares", ["detalleVisitante"]),
    nombreCompleto() {
      if (this.detalleVisitante?.visitante) {
        const { nombres, apellido_paterno, apellido_materno } = this.detalleVisitante.visitante;
        return `${apellido_paterno} ${apellido_materno} ${nombres}`.toUpperCase();
      }
      return "";
    },
    fechaNacimiento() {
      if (this.detalleVisitante?.visitante?.fecha_nacimiento) {
        return moment(this.detalleVisitante.visitante.fecha_nacimiento).format("DD-MM-YYYY");
      }
      return "";
    },
  },
};
</script>
