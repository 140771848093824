<template>
  <div>
    <v-card outlined class="mt-0">
      <v-card-title>LISTADO DE FORMATOS FAMILIARES</v-card-title>
      <v-card-text>
        <v-data-table-server :headers="headers" :items="data" :loading="isLoading" v-model:items-per-page="itemsPerPage"
          v-model:options="options" @update:options="updateParams(options)" :items-length="total" density="compact"
          class="elevation-0" :items-per-page-options="itemsPorPagina">
          <!-- Buscadores superiores -->
          <template v-slot:thead>
            <tr>
              <th>
                <v-text-field v-model="filters.fecha" class="ma-2" density="compact" label="Buscar Fecha" type="date"
                  outlined hide-details @input="applyFilters"></v-text-field>
              </th>
              <th>
                <v-text-field v-model="filters.documento" class="ma-2" density="compact" label="Buscar Documento"
                  outlined hide-details @input="applyFilters"></v-text-field>
              </th>
              <th>
                <v-text-field v-model="filters.nombre" class="ma-2" density="compact" label="Buscar Nombre" outlined
                  hide-details @input="applyFilters"></v-text-field>
              </th>
              <th>
                <v-text-field v-model="filters.tipoVisitante" class="ma-2" density="compact"
                  label="Buscar Tipo Visitante" outlined hide-details @input="applyFilters"></v-text-field>
              </th>
              <th>
                <v-text-field v-model="filters.tipoSeguro" class="ma-2" density="compact" label="Buscar Tipo Seguro"
                  outlined hide-details @input="applyFilters"></v-text-field>
              </th>
              <th>
                <v-text-field v-model="filters.centroSalud" class="ma-2" density="compact"
                  label="Buscar Centro de Salud" outlined hide-details @input="applyFilters"></v-text-field>
              </th>
              <th></th>
              <th></th>
            </tr>
          </template>

          <!-- Renderizando datos -->
          <template v-slot:[`item.created_at`]="{ item }">
            {{ moment(item.created_at).format('DD/MM/YYYY hh:mm A') }}
          </template>
          <template v-slot:[`item.visitante.numero_documento`]="{ item }">
            {{ item.visitante.numero_documento }}
          </template>
          <template v-slot:[`item.visitante.nombre`]="{ item }">
            {{ formatFullName(item.visitante).toUpperCase() }}
          </template>
          <template v-slot:[`item.visitante.tipovisitante.descripcion`]="{ item }">
            {{ item.visitante.tipovisitante.descripcion }}
          </template>
          <template v-slot:[`item.visitante.tiposeguro.descripcion`]="{ item }">
            {{ item.visitante.tiposeguro.descripcion }}
          </template>
          <template v-slot:[`item.visitante.centrosalud.descripcion`]="{ item }">
            {{ item.visitante.centrosalud.descripcion }}
          </template>
          <template v-slot:[`item.habilitacion`]="{ item }">
            <v-chip v-if="item.habilitacion && item.habilitacion.fv_habilitacion.id == 1" color="red" size="small"
              text="NO HABILITADO"></v-chip>
            <v-chip v-if="item.habilitacion && item.habilitacion.fv_habilitacion.id == 2" color="green" size="small"
              text="CONTINUA"></v-chip>
            <v-chip v-if="!item.habilitacion" size="small" color="orange" text="EN PROCESO"></v-chip>
          </template>      
        </v-data-table-server>
      </v-card-text>
    </v-card>

    <!-- Confirmación para eliminar -->
    <ConfirmDialog :isVisible="showDeleteDialog" title="Confirmación"
      message="¿Está seguro de borrar este formato de visita?" @update:isVisible="showDeleteDialog = $event"
      @confirm="confirmarEliminar" @cancel="cancelarEliminar" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

export default {
  name: "ListadoVisitantesFamiliares",
  components: { ConfirmDialog },
  data() {
    return {
      itemsPorPagina: [10, 25, 50, 100],
      moment,
      options: {},
      itemsPerPage: 10,
      filters: {
        fecha: "",
        documento: "",
        nombre: "",
        tipoVisitante: "",
        tipoSeguro: "",
        centroSalud: "",
      },
      showDeleteDialog: false,
      itemToDelete: null,
    };
  },
  computed: {
    ...mapState("formatoVisitaFamiliares", ["data", "total", "isLoading"]),
    headers() {
      return [
        { title: "Fecha", align: "center", sortable: false, key: "created_at" },
        { title: "Número de Documento", align: "center", sortable: false, key: "visitante.numero_documento" },
        { title: "Nombre del Visitante", align: "center", sortable: false, key: "visitante.nombre" },
        { title: "Tipo de Visitante", align: "center", sortable: false, key: "visitante.tipovisitante.descripcion" },
        { title: "Tipo de Seguro", align: "center", sortable: false, key: "visitante.tiposeguro.descripcion" },
        { title: "Centro de Salud", align: "center", sortable: false, key: "visitante.centrosalud.descripcion" },
        { title: "Habilitacion", align: "center", sortable: false, key: "habilitacion" },
      ];
    },
  },
  methods: {
    ...mapActions("formatoVisitaFamiliares", ["fetchFormatoVisita", "updateParams", "deleteFormato"]),
    formatFullName(visitante) {
      return `${visitante.apellido_paterno} ${visitante.apellido_materno} ${visitante.nombres}`;
    },
    applyFilters() {
      // Actualiza los filtros en Vuex
      const { fecha, documento, nombre, tipoVisitante, tipoSeguro, centroSalud } = this.filters;
      this.updateParams({
        fecha,
        documento,
        nombre,
        tipoVisitante,
        tipoSeguro,
        centroSalud,
      });
    },
    editarItem(item) {
      this.$router.push({ path: `/detalleFormatofamiliar/${item.id}` });
    },
    showConfirmDialog(item) {
      this.itemToDelete = item;
      this.showDeleteDialog = true;
    },
    confirmarEliminar() {
      if (this.itemToDelete) {
        this.deleteFormato(this.itemToDelete.id).then(() => {
          this.itemToDelete = null;
          this.showDeleteDialog = false;
        });
      }
    },
    cancelarEliminar() {
      this.showDeleteDialog = false;
      this.itemToDelete = null;
    },
  },
  created() {
    this.updateParams(this.options); // Fetch data when the component is created
  },
};
</script>
