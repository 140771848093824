<template>
  <v-row class="mt-0">
    <!-- Datos del Paciente -->
    <v-col cols="12" lg="3" md="12" sm="12" xs="12">
      <DatosPaciente class="mt-0 mx-4" />
    </v-col>

    <!-- Timeline de Descansos Médicos y Exámenes -->
    <v-col cols="12" lg="3" md="12" sm="12" xs="12">
      <DescansosMedicosTimeline
        class="mt-0"
        :descansos_medicos="descansosMedicos"
        @descanso-seleccionado="handleDescansoSeleccionado"
      />
      <ExamenesOcupacionalesTimeline class="mt-2" />
    </v-col>

    <!-- Timeline de Bitácoras -->
    <v-col cols="12" lg="6" md="12" sm="12" xs="12">
      <BitacorasTimeline
        class="mt-0 mx-4"
        :bitacoras="bitacorasSeleccionadas"
      />
      <CIE10EstadoControl class="mt-2 mx-4" />
    </v-col>
  </v-row>
</template>

<script>
import BitacorasTimeline from "@/components/BitacorasTimeline.vue";
import DatosPaciente from "@/components/DatosPaciente.vue";
import DescansosMedicosTimeline from "@/components/DescansosMedicosTimeline.vue";
import ExamenesOcupacionalesTimeline from "@/components/ExamenesOcupacionalesTimeline.vue";
import CIE10EstadoControl from "@/components/CIE10EstadoControl.vue";

import moment from "moment";

export default {
  name: "DetalleDescansoMedico",
  components: {
    DatosPaciente,
    ExamenesOcupacionalesTimeline,
    DescansosMedicosTimeline,
    BitacorasTimeline,
    CIE10EstadoControl
  },
  data() {
    return {
      moment,
      descansosMedicos: [
        {
          id: 1,
          fechaInicio: "2025-01-13",
          fechaFin: "2025-01-23",
          motivo: "Descanso por enfermedad",
          detalle: "Paciente presentó síntomas gripales severos.",
          bitacoras: [
            {
              fecha: "2024-01-02T10:00:00",
              titulo: "Evaluación inicial",
              descripcion: "El médico evaluó al paciente.",
            },
            {
              fecha: "2024-01-05T14:00:00",
              titulo: "Seguimiento",
              descripcion: "El paciente mostró mejoría en los síntomas.",
            },
          ],
        },
        {
          id: 2,
          fechaInicio: "2024-10-15",
          fechaFin: "2024-10-25",
          motivo: "Recuperación postquirúrgica",
          detalle: "El paciente se sometió a una cirugía menor.",
          bitacoras: [
            {
              fecha: "2024-02-16T11:30:00",
              titulo: "Revisión postquirúrgica",
              descripcion: "Se revisó la cicatriz de la cirugía.",
            },
          ],
        },
      ],
      bitacorasSeleccionadas: [], // Bitácoras del descanso médico seleccionado
    };
  },
  methods: {
    async getData(idDescanso) {
      this.$store.commit("SET_LOADING", true);
      try {
        await this.$store.dispatch(
          "descansosMedicos/fetchDescansoById",
          idDescanso
        );
        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$store.commit("SHOW_ERROR_SNACKBAR", e.message);
        this.$store.commit("SET_LOADING", false);
      }
    },
    async guardarDescanso() {
      try {
        await this.$store.dispatch(
          "saveDescansoMedico",
          this.$route.params.id
        );
        this.$store.commit("SET_LOADING", false);
        this.$store.commit(
          "SHOW_SUCCESS_SNACKBAR",
          "Descanso guardado correctamente"
        );
        this.$router.push({ path: "/descansos-medicos" });
      } catch (e) {
        this.$store.commit("SET_LOADING", false);
        this.$store.commit("SHOW_ERROR_SNACKBAR", e.message);
      }
    },
    handleDescansoSeleccionado(descanso) {
      // Actualizar las bitácoras según el descanso seleccionado
      this.bitacorasSeleccionadas = descanso.bitacoras || [];
    },
  },
  computed: {
    fotos: {
      get() {
        return this.$store.state.fotos_evidencia_descanso;
      },
      set(val) {
        this.$store.commit("SET_FOTOS_EVIDENCIA_DESCANSO", val);
      },
    },
  },
  async created() {
    await this.getData(this.$route.params.id);
    this.$store.commit("SET_EVIDENCIA_DESCANSO_SELECCIONADA", {});
    this.fotos = [];
    await this.$store.dispatch(
      "fetchExamenesMedicos",
      this.$store.state.descansosMedicos.descansoSeleccionado.paciente.id
    );
  },
};
</script>
