import axios from "axios";

const descansosMedicos = {
  namespaced: true,
  state: {
    data: [],
    total: 0,
    descansoSeleccionado: null,
    isLoading: false,
  },
  getters: {
    descansos(state) {
      return state.data;
    },
    descansoById: (state) => (id) => {
      return state.data.find((descanso) => descanso.id === id);
    },
    isLoading(state) {
      return state.isLoading;
    },
  },
  mutations: {
    SET_DESCANSOS(state, data) {
      state.data = data.data;
      state.total = data.total;
    },
    SET_DESCANSO_SELECCIONADO(state, data) {
      state.descansoSeleccionado = data;
    },
    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
  actions: {
    async fetchDescansos({ commit }, params) {
      commit("SET_LOADING", true);
      const config = { params };
      try {
        const { data } = await axios.get("/api/descansomedico", config);
        commit("SET_DESCANSOS", data);
      } catch (error) {
        throw new Error(await error.response.data.message);
      } finally {
        commit("SET_LOADING", false);
      }
    },
    async fetchDescansoById({ commit }, id) {
      try {
        const { data } = await axios.get(`/api/descansomedico/${id}`);
        commit("SET_DESCANSO_SELECCIONADO", data);
      } catch (error) {
        throw new Error(await error.response.data.message);
      }
    },
    async saveDescanso({ dispatch }, descanso) {
      try {
        if (descanso.id) {
          await axios.put(`/api/descansomedico/${descanso.id}`, descanso);
        } else {
          await axios.post("/api/descansomedico", descanso);
        }
        dispatch("fetchDescansos"); // Actualizar la lista después de guardar
      } catch (error) {
        throw new Error(await error.response.data.message);
      }
    },
    async deleteDescanso({ dispatch }, id) {
      try {
        await axios.delete(`/api/descansomedico/${id}`);
        dispatch("fetchDescansos"); // Actualizar la lista después de eliminar
      } catch (error) {
        throw new Error(await error.response.data.message);
      }
    },
    async exportDescansosToExcel(_, params) {
      const config = { params, responseType: "blob" };
      try {
        const response = await axios.get("/api/descansomedico/export", config);
        const url = URL.createObjectURL(
          new Blob([response.data], { type: "application/vnd.ms-excel" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "descansos_medicos.xlsx");
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        throw new Error(await error.response.data.message);
      }
    },
    async searchPacientes(_, { query }) {
        try {
          const response = await axios.get(`/api/pacientes/search`, {
            params: { query }, // Pasa el término de búsqueda como parámetro
          });
          return response.data; // Devuelve la lista de pacientes
        } catch (e) {
          console.error("Error al buscar pacientes:", e.response?.data?.error || e.message);
          throw new Error(e.response?.data?.error || "Error al buscar pacientes");
        }
      },
  },
};

export default descansosMedicos;
