import { createRouter, createWebHashHistory } from "vue-router";
import AppContainer from "@/AppContainer.vue";
import store from "../store";
import ListadoAtencionMedicamentosView from "@/views/ListadoAtencionMedicamentosView";
import LoginView from "@/views/LoginView.vue";
import RegisterView from "@/views/RegisterView.vue";
import DetalleDeclaracion from "@/views/DetalleDeclaracion.vue";
import FormatosView from "@/views/FormatosView";
import FormatosSmcvView from "@/views/FormatosSmcvView";
import DetalleFormato from "@/views/DetalleFormato";
import DescansosMedicosView from "@/views/DescansosMedicosView.vue";
import DetalleDescansoMedico from "@/views/DetalleDescansoMedico.vue";
import FormatosFamiliaresView from "@/views/FormatosFamiliaresView.vue";
import DetalleFormatoFamiliares from "@/views/DetalleFormatoFamiliares.vue";
import FormatosFamiliaresSmcvView from "@/views/FormatosFamiliaresSmcvView.vue";

const routes = [
  {
    path: "/login",
    component: LoginView,
    name: "login",
  },
  {
    path: "/register",
    component: RegisterView,
    name: "register",
  },
  {
    path: "/",
    component: AppContainer,
    meta: { requiresAuth: true },
  },
  {
    path: "/medicamentos",
    component: AppContainer,
    children: [
      {
        path: "",
        component: ListadoAtencionMedicamentosView,
        name: "medicamentos",
        meta: { requiresAuth: true },
      },
      {
        path: "/declaracion/:id",
        component: DetalleDeclaracion,
        name: "declaracion",
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/formatos",
    component: AppContainer,
    children: [
      {
        path: "",
        component: FormatosView,
        name: "listaFormatos",
        meta: { requiresAuth: true },
      },
      {
        path: "/detalleFormato/:id",
        component: DetalleFormato,
        name: "detalleFormato",
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/formatosmcv",
    component: AppContainer,
    children: [
      {
        path: "",
        component: FormatosSmcvView,
        name: "formatosmcv",
        meta: { requiresAuth: true },
      }
    ],
  },
  {
    path: "/formatofamiliares",
    component: AppContainer,
    children: [
      {
        path: "",
        component: FormatosFamiliaresView,
        name: "listaFormatosfamiliares",
        meta: { requiresAuth: true },
      },
      {
        path: "/detalleFormatofamiliar/:id",
        component: DetalleFormatoFamiliares,
        name: "detalleFormatofamiliar",
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/formatofamiliaressmcv",
    component: AppContainer,
    children: [
      {
        path: "",
        component: FormatosFamiliaresSmcvView,
        name: "formatofamiliaressmcv",
        meta: { requiresAuth: true },
      }
    ],
  },
  {
    path: "/descansosmedicos",
    component: AppContainer,
    children: [
      {
        path: "",
        component: DescansosMedicosView,
        name: "descansosmedicos",
        meta: { requiresAuth: true },
      },
      {
        path: "/detalledescansomedico/:id",
        component: DetalleDescansoMedico,
        name: "detalledescansomedico",
        meta: { requiresAuth: true },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (store.getters.isAuthenticated && to.name === "login") {
    next("/");
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next("/login");
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});


export default router;
