<template>
  <v-card outlined>
    <v-toolbar color="indigo-darken-4" dark>
      <v-spacer></v-spacer>
      <h5 class="white--text">CALIFICACIÓN</h5>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <!-- Seleccionar CIE-10 -->
        <v-autocomplete v-model="selectedCIE10" :items="cie10List" label="Seleccione CIE-10" dense required hide-details
          item-text="title" item-value="value" @change="updateClasificacion"></v-autocomplete>

        <!-- Clasificación -->
        <v-text-field hide-details v-model="clasificacion" label="Clasificación" readonly dense></v-text-field>

        <!-- Cambiar Estado -->
        <v-select hide-details v-model="selectedEstado" :items="estados" label="Estado" dense required></v-select>

        <!-- Fecha de Inicio -->
        <v-text-field hide-details v-model="fechaInicio" label="Fecha de Inicio" dense required type="date"></v-text-field>

        <!-- Fecha de Fin -->
        <v-text-field hide-details v-model="fechaFin" label="Fecha de Fin" dense required type="date"></v-text-field>

        <!-- Campo de Días -->
        <v-text-field hide-details v-model="dias" label="Días" readonly dense></v-text-field>

        <!-- Origen -->
        <v-select v-model="selectedOrigen" :items="origenes" label="Origen" dense hide-details required></v-select>

        <!-- Botones -->
        <div class="d-flex mt-4">
          <!-- Botón Guardar -->
          <v-btn color="primary" :disabled="!selectedCIE10 || !selectedEstado || !fechaInicio || !fechaFin || !selectedOrigen
            " class="me-2" @click="guardar">
            Guardar
          </v-btn>

          <!-- Botón Dar de Alta -->
          <v-btn color="success" @click="darDeAlta">Dar de Alta</v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "CIE10EstadoControl",
  data() {
    return {
      valid: false,
      selectedCIE10: null, // CIE-10 seleccionado
      selectedEstado: "SEGUIMIENTO", // Estado seleccionado (por defecto en SEGU
      fechaInicio: null, // Fecha de inicio
      fechaFin: null, // Fecha de fin
      dias: null, // Diferencia en días
      selectedOrigen: null, // Origen seleccionado
      clasificacion: "", // Clasificación del CIE-10 (capítulo)
      cie10Hierarchy: [
        {
          capitulo: "CAPÍTULO I: CIERTAS ENFERMEDADES INFECCIOSAS Y PARASITARIAS",
          grupos: [
            {
              grupo: "(A00 - A09) ENFERMEDADES INFECCIOSAS INTESTINALES",
              categorias: [
                { title: "A00 - Cólera", codes: ["A000", "A001", "A009"] },
                {
                  title: "A009 - Cólera, no especificado",
                  codes: ["A0090", "A0091", "A0092", "A0093"],
                },
              ],
            },
          ],
        },
        // Agrega más capítulos, grupos y categorías aquí...
      ],
      estados: ["SEGUIMIENTO", "FINALIZADO"],
      origenes: ["MINA", "PARTICULAR"],
    };
  },
  computed: {
    cie10List() {
      // Generar lista plana de CIE-10 con títulos y valores
      return this.cie10Hierarchy.flatMap((capitulo) =>
        capitulo.grupos.flatMap((grupo) =>
          grupo.categorias.flatMap((categoria) =>
            categoria.codes.map((code) => ({
              title: `${code} - ${categoria.title}`,
              value: code,
              capitulo: capitulo.capitulo,
            }))
          )
        )
      );
    },
  },
  watch: {
    fechaInicio() {
      this.calculateDias();
    },
    fechaFin() {
      this.calculateDias();
    },
    selectedCIE10() {
      this.updateClasificacion();
    },
  },
  methods: {
    updateClasificacion() {
      // Buscar el capítulo al que pertenece el CIE-10 seleccionado
      const cie10 = this.cie10List.find((item) => item.value === this.selectedCIE10);
      if (cie10) {
        this.clasificacion = cie10.capitulo;
      } else {
        this.clasificacion = "No encontrado";
      }
    },
    guardar() {
      this.$emit("guardar-cambios", {
        cie10: this.selectedCIE10,
        estado: this.selectedEstado,
        fechaInicio: this.fechaInicio,
        fechaFin: this.fechaFin,
        dias: this.dias,
        origen: this.selectedOrigen,
        clasificacion: this.clasificacion,
      });

      this.$store.commit(
        "SHOW_SUCCESS_SNACKBAR",
        "Cambios guardados correctamente."
      );
    },
    darDeAlta() {
      this.$emit("alta-realizada", {
        cie10: this.selectedCIE10,
        estado: this.selectedEstado,
        fechaInicio: this.fechaInicio,
        fechaFin: this.fechaFin,
        dias: this.dias,
        origen: this.selectedOrigen,
        clasificacion: this.clasificacion,
      });

      this.$store.commit(
        "SHOW_SUCCESS_SNACKBAR",
        "Paciente dado de alta correctamente."
      );
    },
    calculateDias() {
      if (this.fechaInicio && this.fechaFin) {
        const inicio = new Date(this.fechaInicio);
        const fin = new Date(this.fechaFin);
        const diffTime = fin - inicio;
        this.dias = diffTime > 0 ? Math.ceil(diffTime / (1000 * 60 * 60 * 24)) : 0;
      } else {
        this.dias = null;
      }
    },
  },
};
</script>