<template>
  <v-card>
    <v-toolbar dense color="indigo-darken-4">
      <v-spacer></v-spacer>
      <h5>INFORMACIÓN ADICIONAL</h5>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>     
      <v-text-field
        v-if="alergias"
        v-model="alergias"
        readonly
        density="compact"
        hide-details
        label="ALERGIAS"
      ></v-text-field>
      <p v-else class="text-center mt-2">NO HAY INFORMACIÓN PARA MOSTRAR</p>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "MedicacionActual",
  computed: {
    ...mapState("formatoVisitaFamiliares", {
      detalleVisitante: (state) => state.detalleVisitante,
    }),
    alergias() {
      return this.detalleVisitante?.visitante?.alergias?.descripcion || null;
    },
  },
};
</script>
