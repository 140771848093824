<template>
  <v-app>
    <v-app-bar color="indigo-darken-4">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>ISOS-SALUD</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon class="mx-2" @click="toggleTheme" icon="mdi-theme-light-dark"></v-icon>
      <template v-if="isAuthenticated">
        <v-menu v-model="menu" :close-on-content-click="false" max-width="400px">
          <template v-slot:activator="{ props }">
            <v-icon v-bind="props" class="mx-2" icon="mdi-account-circle"></v-icon>
          </template>
          <v-card class="mt-0">
            <v-list>
              <v-list-item
                prepend-avatar="/logo.png"
                :title="currentUser.name"
                :subtitle="currentUser.email"
              >
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="orange" variant="outlined" @click="logout">SALIR</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </template>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" class="mx-auto">
      <v-list density="compact" nav>
        <template v-for="(menus, categoria) in categorizedMenus" :key="categoria">
          <v-list-subheader>{{ categoria }}</v-list-subheader>
          <v-list-item
            v-for="menu in menus"
            :key="menu.id"
            link
            :to="`/${menu.route}`"
            :prepend-icon="menu.icon"
            :title="menu.descripcion"
          ></v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view />
    </v-main>
    <v-footer app color="indigo-darken-4" v-if="isAuthenticated">
      &#64;INTERNATIONAL SOS {{ new Date().getFullYear() }}
    </v-footer>
    <v-snackbar timeout="2000" elevation="24" v-model="snackbar.show" :color="snackbar.status">
      {{ snackbar.message }}
    </v-snackbar>
  </v-app>
</template>

<script setup>
import { useTheme } from 'vuetify';

const theme = useTheme();

// Obtener el tema preferido del almacenamiento local al cargar la página
theme.global.name.value = localStorage.getItem('theme') || theme.global.name.value;

function toggleTheme() {
  // Cambiar el tema
  theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark';

  // Guardar la preferencia del tema en el almacenamiento local
  localStorage.setItem('theme', theme.global.name.value);
}
</script>

<script>
import { mapState } from 'vuex';
import { mapGetters } from "vuex";

export default {
  name: 'App',
  data: () => ({
    drawer: null,
    menu: false,
  }),
  computed: {
    ...mapState(['snackbar', 'isLoading']),
    ...mapGetters(['currentUser', 'isAuthenticated']),

    menus() {
      return this.$store.getters["menus"];
    },
    categorizedMenus() {
      const categories = {};
      this.menus.forEach(menu => {
        const categoria = menu.categoria.descripcion;
        if (!categories[categoria]) {
          categories[categoria] = [];
        }
        categories[categoria].push(menu);
      });
      return categories;
    }
  },
  methods: {
    async logout() {
      this.$store.commit('SET_LOADING', true);
      try {
        await this.$store.dispatch('logout');
        await this.$router.push('/login');
        this.$store.commit('SET_LOADING', false);
      } catch (e) {
        this.$store.commit('SHOW_ERROR_SNACKBAR', e.message);
        this.$store.commit('SET_LOADING', false);
      }
    }
  }
};
</script>
