import axios from "axios";

const formatoVisitaFamiliares = {
  namespaced: true,
  state: {
    data: [],
    total: 0,
    isLoading: false,
    params: {},
    detalleVisitante: null,
    habilitacionesCalificacionFormatoVisitante: [],
    cuestionario: [],
  },
  mutations: {
    SET_DATA(state, { data, total }) {
      state.data = data;
      state.total = total;
    },
    SET_HABILITACIONES(state, data) {
      state.habilitacionesCalificacionFormatoVisitante = data;
    },
    SET_CUESTIONARIO(state, data) {
      state.cuestionario = data;
    },
    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    SET_PARAMS(state, params) {
      state.params = { ...state.params, ...params };
    },
    SET_DETALLE_VISITANTE(state, detalle) {
      // Asegurar estructura predeterminada
      state.detalleVisitante = {
        ...detalle,
        habilitacion: detalle.habilitacion || { fvfamiliar_habilitacion_id: null, observaciones: "" ,user_id: null},
      };
    },
    SET_HABILITACION(state, habilitacion) {
      if (!state.detalleVisitante) {
        state.detalleVisitante = {};
      }
      state.detalleVisitante.habilitacion = habilitacion;
    },
    SET_SIGNOS_VITALES(state, signos) {
      if (!state.detalleVisitante) {
        state.detalleVisitante = {};
      }
      if (!state.detalleVisitante.signosvitales) {
        state.detalleVisitante.signosvitales = {};
      }
      state.detalleVisitante.signosvitales = {
        ...state.detalleVisitante.signosvitales,
        ...signos,
      };
    },
    SET_ALERGIAS(state, alergias) {
      if (!state.detalleVisitante) {
        state.detalleVisitante = {};
      }
      if (!state.detalleVisitante.visitante) {
        state.detalleVisitante.visitante = {};
      }
      state.detalleVisitante.visitante.alergias = alergias;
    },
  },
  actions: {
    async fetchFormatoVisita({ commit, state }) {
      commit("SET_LOADING", true);
      try {
        const { data } = await axios.get("/api/formatovisitafamiliar", {
          params: state.params,
        });
        commit("SET_DATA", { data: data.data, total: data.total });
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        commit("SET_LOADING", false);
      }
    },
    async fetchFormatoVisitaID({ commit }, id) {
      commit("SET_LOADING", true);
      try {
        const { data } = await axios.get(`/api/formatovisitafamiliar/${id}`);
        commit("SET_DETALLE_VISITANTE", data);
        return data;
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        commit("SET_LOADING", false);
      }
    },
    async fetchFvHabilitacionFormatoVisitante({ commit }) {
      try {
        const { data } = await axios.get(`/api/Fvhabilitacionfamiliar`);
        commit("SET_HABILITACIONES", data);
        return data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async guardarFormatoVisita({ state, commit }) {
      try {
        await axios.post("/api/formatovisitafamiliar", state.detalleVisitante);
        commit("SHOW_SUCCESS_SNACKBAR", "Formato Actualizado Correctamente");
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async updateParams({ commit, dispatch }, params) {
      commit("SET_PARAMS", params);
      await dispatch("fetchFormatoVisita");
    },
    async fetchCuestionario({ commit }) {
      try {
        const { data } = await axios.get(`/api/preguntaformato`);
        commit("SET_CUESTIONARIO", data);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
};

export default formatoVisitaFamiliares;
