<template>
  <v-card>
    <v-toolbar dense color="indigo-darken-4">
      <v-spacer></v-spacer>
      <h5>CONDICIÓN MÉDICA</h5>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <v-switch
        v-for="pregunta in cuestionario"
        :key="pregunta.id"
        density="compact"
        :label="pregunta.descripcion"
        color="success"
        readonly
        hide-details
        :modelValue="isSwitchActive(pregunta.id)"
      ></v-switch>
      <v-text-field
        v-if="otros"
        v-model="otros.respuesta"
        hide-details
        density="compact"
      ></v-text-field>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "ListadoMedicamentos",
  computed: {
    ...mapState("formatoVisitaFamiliares", {
      cuestionarioPaciente: (state) => state.detalleVisitante?.preguntas || [],
      otros: (state) => state.detalleVisitante?.otrospregunta || null,
      cuestionario: (state) => state.cuestionario || [],
    }),
  },
  methods: {
    ...mapActions("formatoVisitaFamiliares", ["fetchCuestionario"]),
    isSwitchActive(preguntaId) {
      return Boolean(
        this.cuestionarioPaciente.some(
          (pregunta) => pregunta.pivot?.pregunta_formatovisitafamiliar_id === preguntaId
        )
      );
    },
  },
  created() {
    this.fetchCuestionario();
  },
};
</script>
